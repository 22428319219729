import React from 'react';
import cc from 'classcat';

export interface Props {
  buttons?: React.ReactNode
  className?: string
  subTitle?: React.ReactNode
  title: React.ReactNode
}

const Jumbotron: React.FC<Props> = (props) => {
  const {
    buttons,
    className,
    subTitle,
    title,
  } = props;

  return (
    <div
      className={cc([
        'mb-8',
        className,
      ])}
    >
      <div className="fc-jumbotron max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <div>
          <h2
            className={cc([
              'font-display',
              'text-3xl',
              'leading-9',
              'font-extrabold',
              'tracking-tight',
              'sm:text-4xl',
              'sm:leading-10',
              // `text-${bgColor}-accent`
            ])}
          >
            {title}
          </h2>
          {
            subTitle &&
            <h3
              className={cc([
                'text-2xl',
                'leading-9',
                'font-extrabold',
                'tracking-tight',
                // `text-${bgColor}-accent`
              ])}
            >
              {subTitle}
            </h3>
          }
        </div>
        <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0">
          {
            React.Children.map(buttons, button => (
              <div className="ml-3 inline-flex">
                {button}
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
};

Jumbotron.displayName = 'FcJumbotron';

export default Jumbotron;
