interface Data {
  callback?: () => void;
  props?: { [key: string]: string | number | boolean };
}

declare global {
  interface Window {
    plausible: (event: string, data?: Data) => void;
  }
}

export function track(event: string, data?: Data) {
  window?.plausible?.(event, data);
}
