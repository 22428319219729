import React from 'react';
import cc from 'classcat';
import SocialMediaButton from './social-media-button';

import { socialMedias } from '../../data';

export interface Props {
  className?: string;
}

const SocialMediaButtons: React.FC<Props> = (props) => {
  const { className } = props;

  return (
    <div
      className={cc([
        'w-full',
        'flex',
        'flex-row',
        'md:justify-center',
        className,
      ])}
    >
      <div className="p-4 md:text-center">
        {socialMedias.map((socialMedia) => (
          <SocialMediaButton
            key={socialMedia.key}
            href={socialMedia.href}
            icon={socialMedia.icon}
            id={socialMedia.key}
            title={socialMedia.title}
          />
        ))}
      </div>
    </div>
  );
};

SocialMediaButtons.displayName = 'FcSocialMediaButtons';

export default SocialMediaButtons;
