import React from 'react';
import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';

import { track } from '../utils/analytics';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Breadcrumbs from '../components/breadcrumbs';
import Button from '../components/button';
import Jumbotron from '../components/jumbotron';
import Section from '../components/section';
import SocialMediaButtons from '../components/social-media-button/social-media-buttons';

const NotFoundPage: React.FC<PageProps> = (props) => {
  const { location } = props;

  const intl = useIntl();

  React.useEffect(() => {
    track('404', {
      props: {
        pathname: location.pathname,
      },
    });
  }, []);

  return (
    <>
      <SEO
        lang={intl.locale.split('-')[0]}
        title={intl.formatMessage({
          id: 'Page not found',
          defaultMessage: 'Page not found',
        })}
      />

      <Layout
        breadcrumbs={
          <Breadcrumbs
            items={[
              { to: '/', title: intl.formatMessage({ id: 'Start', defaultMessage: 'Start' }) },
              { to: `/${location.pathname.replace(/\/?\w{2}-\w{2}\/?/g, '')}`, title: '404' },
            ]}
          />
        }
        path={location.pathname}
        buttons={
          <Button href="/" variant="tertiary">
            <FormattedMessage id="Go back home" defaultMessage="Go back home" />
          </Button>
        }
        image={
          <StaticImage
            alt={intl.formatMessage({
              id: 'An aerial image of the river in Gothenburg',
              defaultMessage: 'An aerial image of the river in Gothenburg',
            })}
            src="../assets/images/edvin-johansson-lrhtitoYwGs-unsplash.jpg"
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            draggable={false}
            placeholder="blurred"
          />
        }
        title={
          <span>
            <span>
              <FormattedMessage
                id="The page you requested"
                defaultMessage="The page you requested"
              />{' '}
              <span className="text-primary">
                <FormattedMessage id="couldn't be found" defaultMessage="couldn't be found" />
              </span>
            </span>
          </span>
        }
        preMain={
          <Jumbotron
            className="bg-black"
            title={
              <>
                <FormattedMessage id="Not to worry." defaultMessage="Not to worry." />
                <br />
                <span className="text-primary">
                  <FormattedMessage
                    id="This is just temporary."
                    defaultMessage="This is just temporary."
                  />
                </span>
              </>
            }
            subTitle={
              <FormattedMessage
                id="There is an easy way back, just look to the right."
                defaultMessage="There is an easy way back, just look to the right."
              />
            }
            buttons={
              <Button href="/" variant="tertiary">
                <FormattedMessage id="Go back home" defaultMessage="Go back home" />
              </Button>
            }
          />
        }
      >
        <Section
          id="contact"
          title={intl.formatMessage({
            id: 'Contact',
            defaultMessage: 'Contact',
          })}
        >
          <SocialMediaButtons className="mt-8 lg:mt-16" />
        </Section>
      </Layout>
    </>
  );
};

export default NotFoundPage;
