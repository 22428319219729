import React from 'react';
import cc from 'classcat';
import { useIntl } from 'gatsby-plugin-intl';

import './social-media-button.css';

export interface Props {
  href: string;
  icon: React.ReactElement;
  id: string;
  title: string;
}

const SocialMediaButton: React.FC<Props> = (props) => {
  const { href, icon, id, title } = props;

  const intl = useIntl();

  const titleSuffix = intl.formatMessage(
    {
      id: 'External link to: {host}',
      defaultMessage: 'External link to: {host}',
    },
    {
      host: href
        .replace('http://', '')
        .replace('https://', '')
        .replace('ftp://', '')
        .replace('ftps://', '')
        .split(/[/?#]/)[0],
    }
  );

  const computedTitle = title ? `${title} | ${titleSuffix}` : titleSuffix;

  return (
    <a
      id={`fc-social-media-button--${id}`}
      rel="noopener noreferrer"
      href={href}
      title={computedTitle}
      target="_blank"
      className={cc([
        'fc-social-media-button',
        `fc-social-media-button--${id}`,
        'm-2',
        'px-4',
        'py-2',
        'inline-flex',
        'text-xl',
        'font-black',
        'hover:bg-blue-100',
        'dark:hover:bg-gray-800',
        'focus-visible:bg-blue-200',
        'focus:outline-none',
        'dark:focus-visible:bg-gray-700',
        'rounded-full',
        'motion-safe:transition',
        'duration-150',
        'focus-visible:ring-2',
        'ring-primary',
        'ring-offset-4',
        'ring-offset-gray-100',
        'dark:ring-offset-gray-800',
      ])}
    >
      <div
        className={cc([
          'fc-social-media-button__icon',
          `fc-social-media-button__icon--${id}`,
          'inline-flex',
          'flex-row',
          'items-center',
          'justify-center',
        ])}
      >
        {icon && React.cloneElement(icon, {
          title: computedTitle,
          className: 'mr-2',
        })}

        {title}
      </div>
    </a>
  );
};

SocialMediaButton.displayName = 'FcSocialMediaButton';

export default SocialMediaButton;
